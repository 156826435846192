<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    v-if="this.$root.mobile"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card>
      <!-- <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title> -->
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.diagnoses.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-form ref="mdDiagnosesForm" v-model="valid">
            <v-autocomplete
              v-model="mdDiagnosesForm.illness"
              clearable
              outlined
              :items="itemTypes"
              :loading="itemTypesLoaded"
              :filter="typeFilter"
              :rules="[v => !!v || $t('MF.diagnoses.validation.type')]"
              :label="$t('MF.diagnoses.form.type')"
              item-text="name"
              item-value="id"
              return-object
            >
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
              <template v-slot:item="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>

            <v-textarea
              name="entry_body"
              outlined
              :label="$t('MF.diagnoses.form.details')"
              auto-grow
              v-model="mdDiagnosesForm.examination"
              :rules="examinationRules"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t("AUTH.GENERAL.CANCEL_BUTTON") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" persistent max-width="700px" v-else>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("MF.diagnoses.update") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="mdDiagnosesForm" v-model="valid">
            <div class="row">
              <div class="col-lg-11 col-md-11 col-sm-11">
                <v-autocomplete
                  v-model="mdDiagnosesForm.illness"
                  clearable
                  outlined
                  :items="itemTypes"
                  :loading="itemTypesLoaded"
                  :filter="typeFilter"
                  :rules="[v => !!v || $t('MF.diagnoses.validation.type')]"
                  :label="$t('MF.diagnoses.form.type')"
                  item-text="name"
                  item-value="id"
                  return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }}
                  </template>
                </v-autocomplete>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-1">
                <v-btn
                  color="primary"
                  class="white--text"
                  fab
                  @click="dialog = true"
                >
                  <v-icon dark> mdi-plus-box </v-icon>
                </v-btn>
              </div>
            </div>
            <v-textarea
              name="entry_body"
              outlined
              :label="$t('MF.diagnoses.form.details')"
              auto-grow
              v-model="mdDiagnosesForm.examination"
              :rules="examinationRules"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error darken-1" text @click="close">
          {{ $t("AUTH.GENERAL.CANCEL_BUTTON") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["dialog", "record"],
  data() {
    return {
      error: false,
      submitted: false,
      errorMsg: "",
      itemTypes: [],
      itemTypesLoaded: true,
      selectedType: "",
      menu2: false,
      valid: false,
      askStarted: false,
      examinationRules: [
        v => !!v || this.$t("MF.diagnoses.validation.details"),
        v => v.trim().length >= 10 || this.$t("MF.diagnoses.validation.length")
      ],
      mdDiagnosesForm: {
        illness: "",
        examination: ""
      },
      date: new Date().toISOString().substr(0, 10),
      body: ""
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.mdDiagnosesForm.validate()) {
          this.submitted = true;

          await ApiService.update(
            "items/md_diagnoses",
            this.record.id,
            this.mdDiagnosesForm
          );
          this.submitted = false;
          this.$refs.mdDiagnosesForm.reset();
          this.$emit("formSubmitted", "Diagnoses Updated Successfully");
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        // console.log(error);
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "illnesses?fields=id,name,symptoms"
        );
        this.itemTypes = data.data;
        this.itemTypesLoaded = false;
      } catch (error) {
        this.error = error;
        // console.log(error);
      }
    },

    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    this.getItemTypes();

    this.record &&
      Object.assign(this.mdDiagnosesForm, {
        illness: this.record.illness,
        examination: this.record.examination
      });
  }
};
</script>
